import {mapActions, mapGetters, mapMutations} from "vuex";

import mainButton from '@/components/main-button/index.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "meeting",
  components:{
    mainButton
  },
  watch: {

  },
  data(){
    return{

    }
  },
  created() {

  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({

    }),
  },

  methods:{
    ...mapActions({

    }),
    ...mapMutations({

    }),
    scroll(){
      window.scrollTo({
        top: 550,
        behavior: "smooth"
      });
    },
  }
}